<template>
  <div
    class="columns is-marginless has-padding-50 has-padding-bottom-400 is-multiline"
  >
    <div class="column is-12">
      <card>
        <template slot="header">
          <p class="card-header-title">
            <strong>Products</strong>
          </p>
        </template>
        <template slot="body">
          <products-table @edit="editProduct" @new="addProduct" />
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import { collection, doc } from "@firebase/firestore";
export default {
  name: "Products",
  components: {
    "products-table": () => import("@shared/product/_productsTable")
  },
  methods: {
    addProduct() {
      const productsRef = doc(collection(this.$firestore, `products`));
      this.$router.push({ path: `/admin/settings/products/${productsRef.id}` });
    },
    editProduct(product) {
      this.$router.push({ path: `/admin/settings/products/${product._id}` });
    }
  }
};
</script>
